<template>
  <div>
    <form @submit.prevent="validateForm()">
      <div class="form-group">
        <label>Procuring Entity</label>
        <v-select
          v-model="bid_bond.counter_party"
          v-validate="'required'"
          :options="counterparties"
          label="name"
          data-vv-name="procuring_entity"
          name="procuring_entity"
          placeholder="Select Company Tendering From"
        />
        <small
          v-if="errors.has('procuring_entity')"
          class="text-danger"
        >{{ errors.first('procuring_entity') }}
        </small>
      </div>

      <div class="form-group">
        <label>Tender Purpose</label>
        <textarea
          v-model="bid_bond.tender_purpose"
          v-validate="'required'"
          rows="3"
          name="tender_purpose"
          class="form-control"
          placeholder="eg. Tender for provision of auctioneering services"
        />
        <small
          v-if="errors.has('tender_purpose')"
          class="text-danger"
        >{{ errors.first('tender_purpose') }}</small>
      </div>
      <div class="form-group">
        <div class="placeholder-red">
          Strictly type the "Addressee" without including P.O.Box or Physical address such as : Director General,
          Managing Director, Head of Procurement etc.
        </div>
        <label>Addressee</label>
        <textarea
          v-model="bid_bond.addressee"
          v-validate="'required'"
          rows="3"
          placeholder="e.g General Manager"
          class="form-control"
          name="addressee"
        />
        <small
          v-if="errors.has('addressee')"
          class="text-danger"
        >{{ errors.first('addressee') }}</small>
      </div>
      <div class="form-group">
        <a
          href="#"
          class="btn btn-primary pull-left"
          @click.prevent="$emit('back')"
        ><i class="fa fa-chevron-left"></i> Previous</a>
        <button
          type="submit"
          class="btn btn-primary pull-right"
        >
          Next <i class="fa fa-chevron-right"></i>
        </button>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    current: {
      type: Object
    },
    counterparties: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  data() {
    return {
      bid_bond: {
        counter_party: null,
        tender_purpose: '',
        addressee: '',
      },
    }
  },
  watch: {
    current(newval) {
      newval.amount ? this.bid_bond.amount = newval.amount : this.bid_bond.amount = '';
      newval.tender_purpose ? this.bid_bond.tender_purpose = newval.tender_purpose : this.bid_bond.tender_purpose = '';
      newval.addressee ? this.bid_bond.addressee = newval.addressee : this.bid_bond.addressee = '';
    }
  },
  mounted() {
    this.current.counter_party ? this.bid_bond.counter_party = this.current.counter_party : this.bid_bond.counter_party = null;
    this.current.tender_purpose ? this.bid_bond.tender_purpose = this.current.tender_purpose : this.bid_bond.tender_purpose = '';
    this.current.addressee ? this.bid_bond.addressee = this.current.addressee : this.bid_bond.addressee = '';
  },
  methods: {
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('step2', this.bid_bond);
        }
      });
    }
    ,
  }
}
</script>
