<template>
  <div class="container">
    <div class="form-group">
      <label>Select Template</label>
      <v-select
          v-model="selected_template"
          :options="templates"
          label="name"
          placeholder="Select Template"
          required
      />
    </div>
    <div class="form-group">
      <input
          id="terms"
          v-model="terms"
          type="checkbox"
      >
      <label
          for="terms"
          style="margin-left: 10px;"
      >I have read the <a
          href="#"
          @click.prevent="show_terms=true"
      >terms
        and conditions.</a></label>
    </div>
    <hr>
    <div class="form-group">
      <div
          id="bid-bond-template"
          class="preview"
          style="background: #fff url(/img/sample.png) no-repeat; background-size: contain; background-position-x: center;"
      >
        <div v-html="preview"/>
      </div>
    </div>
    <hr>
    <div class="form-group">
      <a
          class="btn btn-primary"
          href="#"
          @click.prevent="$emit('back')"
      ><i class="fa fa-chevron-left"></i> Previous</a>
      <button
          class="btn btn-success pull-right"
          @click.prevent="selectTemplate"
      >
        Proceed <i class="fa fa-check-circle"></i>
      </button>
    </div>
    <modal
        :show-modal="show_terms"
        @close="show_terms=false"
    >
      <template v-slot:header>
        <h5>Terms and Conditions</h5>
      </template>
      <div>
        <bid-terms/>
      </div>
      <hr>
      <div class="form-group">
        <a
            class="btn btn-success"
            href="#"
            @click.prevent="show_terms = false; terms = true;"
        ><i class="fa fa-check-circle"></i> Accept Terms and
          Conditions</a>
        <a
            class="btn btn-danger pull-right"
            href="#"
            @click.prevent="show_terms = false"
        > Cancel</a>
      </div>
    </modal>
  </div>
</template>

<script>
import BidTerms from "@/components/guest/BidTerms.vue";

export default {
  components: {
    BidTerms
  },
  props: {
    current: {
      type: Object,
    },
  },
  data() {
    return {
      templates: [],
      selected_template: {},
      preview: '',
      terms: false,
      show_terms: false
    }
  },
  watch: {
    selected_template(n) {
      if (!n) return;
      this.$axios.post('/api/v1/bid-bonds/preview', {
        "company_id": this.current.company.company_unique_id,
        "counter_party_id": this.current.counter_party.id,
        "addressee": this.current.addressee,
        "company": this.current.company.name,
        "joint_venture": this.current.joint_venture,
        "purpose": this.current.tender_purpose,
        "tender_no": this.current.tender_number,
        "effective_date": this.current.start_date,
        "expiry_date": this.current.end_date,
        "template_secret": this.selected_template.secret,
        "amount": this.current.amount,
        "period": this.current.period,
        "currency": this.current.currency,
      }).then(response => {
        this.preview = response.data;
      });
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      const self = this;
      this.$axios.get('/api/v1/counter-party-templates/' + this.current.counter_party.id).then(response => {
       this.templates = response.data;
        if (this.current.template) {
          const selected_t = this.templates.filter(res => {
            return res.secret === self.current.template;
          });
          if (selected_t) {
            this.selected_template = selected_t[0];
          }
        } else {
          this.selected_template = this.templates[0];
        }
      });
    },
    selectTemplate() {
      if (this.terms) {
        return this.$emit('selected', this.selected_template.secret);
      } else {
        window.scrollTo(0, 0);
        this.$toastr.e('Please accept the terms and conditions');
      }
    }
  }
}
</script>

