<template>
  <div>
    <form class="row" @submit.prevent="validateForm">
      <div class="form-group col-md-4">
        <label>Currency</label>
        <v-select
            v-model="bid_bond.currency"
            v-validate="'required'"
            :options="currencies"
            name="currency"
            placeholder="Select Currency"
        />
        <small
            v-if="errors.has('currency')"
            class="text-danger"
        >{{ errors.first('currency') }}
        </small>
      </div>
      <div class="form-group col-md-4">
        <label>BidBond Amount</label>
        <vue-numeric
          v-model="bid_bond.amount"
          v-validate="'required|max_value:' + get_Limit"
          name="bidbond_amount"
          class="form-control"
        />
        <small
          v-if="errors.has('bidbond_amount')"
          class="text-danger"
        >{{ errors.first('bidbond_amount') }}
        </small>
      </div>
      <div class="form-group col-md-4">
        <label>Tender Period (days)</label>
        <vue-numeric
          v-model="bid_bond.period"
          v-validate="'required|min_value:30'"
          class="form-control"
          name="tender_period"
        />
        <small
          v-if="errors.has('tender_period')"
          class="text-danger"
        >{{ errors.first('tender_period') }}
        </small>
      </div>
      <div class="form-group col-md-4">
        <label>Start Date</label>
        <input
          ref="yesterdays_date"
          v-model="maxStartDate"
          type="hidden"
        >
        <date-picker
          v-model="bid_bond.start_date"
          v-validate="'required|date_format:yyyy-MM-dd|after:yesterdays_date'"
          format="yyyy-MM-dd"
          name="bid_bond_start_date"
          input-class="form-control bg-white"
          :disabled-dates="disabledDates"
        />
        <small
          v-if="errors.has('bid_bond_start_date')"
          class="text-danger"
        >
          {{ errors.first('bid_bond_start_date') }}
        </small>
      </div>
      <div class="form-group col-md-4">
        <label>End Date</label>
        <input
          v-model="end_date"
          type="text"
          class="form-control"
          readonly
        >
      </div>
      <div class="form-group col-md-12">
        <a
          href=""
          class="btn btn-primary pull-left"
          @click.prevent="$emit('back')"
        ><i class="fa fa-chevron-left"></i> Previous</a>
        <button
          class="btn btn-primary pull-right"
          type="submit"
        >
          Next <i class="fa fa-chevron-right"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';
import {currencies} from "@/mixins/currencies";

export default {
  components: {
    DatePicker
  },
  props: {
    current: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      individual_limit:0,
      bid_bond: {
        amount: '',
        currency: 'KES',
        period: '',
        start_date: '',
        end_date: ''
      },
      maxStartDate: moment().add(-1, 'day').format('yyyy-MM-DD'),
      disabledDates: {
        to: moment().add(-1, 'day').toDate()
      },
      pre_pricing: {}
    }
  },
  computed: {
    end_date() {
      if (this.bid_bond.start_date && this.bid_bond.period) {
        let end_date = moment(this.bid_bond.start_date).add(parseInt(this.bid_bond.period), 'days').format('YYYY-MM-DD');
        this.formatStartDate();
        return end_date;
      }
      return '';
    },
    get_Limit(){
      if(this.current.company.balance && this.individual_limit ){
        return (this.current.company.balance > this.individual_limit) ? this.individual_limit : this.current.company.balance;
      }
      return 5000000.00;// Default of 5,000,000.00
    }
  },

  watch: {
    end_date(newval) {
      this.bid_bond.end_date = newval;
    },

    current(newval) {
      newval.currency ? this.bid_bond.currency = newval.currency : this.bid_bond.currency = 'KES';
      newval.amount ? this.bid_bond.amount = newval.amount : this.bid_bond.amount = '';
      newval.period ? this.bid_bond.period = newval.period : this.bid_bond.period = '';
      newval.start_date ? this.bid_bond.start_date = newval.start_date : this.bid_bond.start_date = '';
    }
  },
  created() {
    this.currencies = currencies;
  },
  mounted() {
    this.current.currency ? this.bid_bond.currency = this.current.currency : this.bid_bond.currency = '';
    this.current.amount ? this.bid_bond.amount = this.current.amount : this.bid_bond.amount = '';
    this.current.period ? this.bid_bond.period = this.current.period : this.bid_bond.period = '';
    this.current.start_date ? this.bid_bond.start_date = this.current.start_date : this.bid_bond.start_date = '';
    this.getIndividualLimit();
  },
  methods: {
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('step3', this.bid_bond);
        }
      });
    },
    formatStartDate() {
      this.bid_bond.start_date = moment(this.bid_bond.start_date).format(
          "YYYY-MM-DD"
      );
    },
    getIndividualLimit(){
      this.$axios.get('/api/v1/bid-bond-limit', this.user).then(response => {
        if(response.data.limit){
          this.individual_limit = response.data.limit;
        }
      });
    },
  }
}
</script>
