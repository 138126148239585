<template>
  <div>
    <form @submit.prevent="$emit('step4')">
      <h4>Summary</h4>
      <hr>
      <div
        v-if="is_valid"
        class="row"
      >
        <div class="col-md-6">
          <div class="bond-content">
            <p><b>Company:</b> {{ company_name }}</p>
            <p><b>Tender Number: </b>{{ bid_bond.tender_number }}</p>
            <p><b>Addressee: </b></p>
            <p>{{ bid_bond.addressee }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="bond-content">
            <p><b>Procuring Entity: </b> {{ bid_bond.counter_party.name }}</p>
            <p><b>Tender Purpose: </b></p>
            <p>{{ bid_bond.tender_purpose }}</p>
          </div>
        </div>
      </div>
      <hr>
      <div
        v-if="is_valid"
        class="row"
      >
        <div class="col-md-6">
          <div class="bond-content">
            <p><b>BidBond Amount: </b> {{ bid_bond.currency }} {{ $number.format(bid_bond.amount) }}</p>
            <p><b>Tender Period: </b>{{ bid_bond.period }}</p>
            <p><b>Start Date: </b> {{ start_date }}</p>
            <p><b>End Date: </b> {{ bid_bond.end_date }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="bond-content">
            <p><b>BidBond Charge: </b> {{ bid_bond.currency }} {{ $number.format(pricing.bid_bond_charge) }}</p>
            <p><b>Excise Duty: </b> {{ bid_bond.currency }} {{ $number.format(pricing.excise_duty) }}</p>
            <p><b>Indemnity Cost: </b> {{ bid_bond.currency }} {{ $number.format(pricing.indemnity_cost) }}</p>
            <p><b>Total: </b> {{ bid_bond.currency }} {{ $number.format(pricing.total) }}</p>
          </div>
        </div>
      </div>
      <br v-if="is_valid">
      <div
        v-if="! is_valid"
        class="alert alert-danger"
      >
        <p>The bidbond amount is invalid. Please try a different amount.</p>
      </div>
      <div class="form-group">
        <a
          href=""
          class="btn btn-primary pull-left"
          @click.prevent="$emit('back')"
        ><i class="fa fa-chevron-left"></i> Previous</a>
        <button
          v-if="is_valid"
          class="btn btn-success pull-right"
        >
          Confirm <i class="fa fa-check-circle"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment/src/moment';

export default {
  props: {
    bid_bond: {
      type: Object,
      default() {
        return {
          company: {},
          counter_party: {}
        }
      }
    },
    pricing: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    start_date() {
      return moment(this.bid_bond.start_date).format('YYYY-MM-DD')
    },
    company_name(){
      if(this.bid_bond.joint_venture) {
        return `${this.bid_bond.company.name}-${this.bid_bond.joint_venture} JV`;
      }
      return this.bid_bond.company.name;
    },
    is_valid() {
      return this.pricing.bid_bond_charge != null;
    }
  }
}
</script>
